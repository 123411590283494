
import { defineComponent } from 'vue';
import * as Yup from 'yup';
import ApiService from '@/core/services/ApiService';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import { apiEndpoint } from '@/mixin/apiEndpoint';
import moment from 'moment';
export default defineComponent({
  mixins: [apiEndpoint],
  name: 'search-trainee',
  components: {
    //Datatable,
  },
  data() {
    return {
      api_url: '',
      moment: '' as any,
      trainee: {
        registration_number: '',
        reference_number: '',
        nid: '',
        bcn: '',
        mobile: '',
      },
      entity_id: '' as any,
      training_institute_id: '' as any,
      course_info_id: '' as any,
      batch_info_id: '' as any,
      traineeInfo: {
        entity_id: '',
        training_institute_id: '',
        course_info_id: '',
        batch_info_id: '',
      },
      load: false,
      showTraineeInfo: false,
      trainee_id: '' as any,
    };
  },
  async created() {
    this.moment = moment;
    this.api_url = this.VUE_APP_API_URL;
    let data = window.location.search;
    let urlParams = new URLSearchParams(data);
    this.trainee_id = urlParams.get('trainee_id');
    await this.getTraineeInfo();
  },
  methods: {
    async getTraineeInfo() {
      // if (
      //   this.trainee.registration_number ||
      //   this.trainee.reference_number ||
      //   this.trainee.nid ||
      //   this.trainee.bcn ||
      //   this.trainee.mobile
      // ) {

      this.load = true;
      await ApiService.get('trainee/search?trainee_id=' + this.trainee_id)
        .then((response) => {
          this.load = false;
          this.traineeInfo = response.data.data;
          this.showTraineeInfo = true;
          this.entity_id = this.traineeInfo.entity_id;
          this.training_institute_id = this.traineeInfo.training_institute_id;
          this.course_info_id = this.traineeInfo.course_info_id;
          this.batch_info_id = this.traineeInfo.batch_info_id;
        })
        .catch(({ response }) => {
          console.log(response);
        });
      // }
      // else {
      //   Swal.fire({
      //     title: 'Error!',
      //     text: 'Please give input first!',
      //     icon: 'error',
      //     buttonsStyling: false,
      //     confirmButtonText: 'Close',
      //     customClass: {
      //       confirmButton: 'btn btn-danger',
      //     },
      //   });
      // }
    },
  },
});
